import jQuery from 'jquery'

window.jQuery = jQuery
window.$ = jQuery


import whatInput from 'what-input';
import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';
//import './lib/foundation-explicit-pieces';

import './lib/jquery.rwdImageMaps.min';


$(document).foundation();

$(document).ready(function(e) {
});

$(window).on('load', function () {
  $('img[usemap]').rwdImageMaps();
});